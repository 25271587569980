import React, { useContext, useState } from "react"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography, Grid, CardMedia, Box } from "@material-ui/core"
import ButtonBack from "@components/Buttons/ButtonBack.jsx"
import { IntlContextConsumer, navigate } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "@src/heplers"
import { makeComponentStyles } from "@components/mixins.js"
import BaseModal from "@components/Dialog/BaseModal.jsx"
import { SRLWrapper, useLightbox } from "simple-react-lightbox"
import clsx from "clsx"
import Seo from "../../components/seo"

const styles = (_) => ({
  small_card: {
    transition: "transform .25s ease",
    "&:hover": {
      transform: "scale(1.05)"
    }
  },
  pointer: {
    cursor: "pointer"
  }
})

const SRLSerializer = images => images.map(image => ({
  src: image.localFile.publicURL,
  width: 1024,
  height: "auto"
}))

function Show({ pageContext }) {

  const { language } = useContext(IntlContextConsumer)
  const payload = getStrapiContentByLang(pageContext.payload, language)
  const index = pageContext.index

  const { openLightbox } = useLightbox();

  const {
    small_card, pointer
  } = makeComponentStyles(styles)

  const { GallerySEO } = payload;

  return (
    <>
      <Seo title={GallerySEO?.TitleSEO}
           description={GallerySEO?.DescriptionSEO}
           keywords={GallerySEO?.KeywordsSEO?.split(',')}
      />
      <Layout>
        <Container>
          <MainWrap>
            <MainWrap.Header>
              <ButtonBack click={() => navigate(`/photo_gallery/${pageContext.previousPage}`)} />
              <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                {payload.contents[index]?.title}
              </Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <Grid container spacing={3}>
                {
                  payload.contents[index]?.photos.map(({ localFile: { publicURL } }, number) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CardMedia
                        component="img"
                        height="190"
                        image={publicURL}
                        alt="photo"
                        className={clsx(small_card, { [pointer]: true } )}
                        onClick={() => openLightbox(number)}
                      />
                    </Grid>
                  ))
                }
              </Grid>
              <SRLWrapper elements={
                SRLSerializer(
                  payload.contents[index]?.photos || []
                )
              } />
            </MainWrap.Body>
          </MainWrap>
        </Container>
      </Layout>
    </>
  )
}

export default Show
